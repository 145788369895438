import Vue from 'vue';
import VueRouter from 'vue-router';
import $store from '../store/index';
import languageList from '../configs/languageList';
import languageUtil from '../utils/languageUtil';

Vue.use(VueRouter);

/**
 * Usage: component: () => import(/* webpackChunkName: 'example' * / '../views/Example.vue')
 * route level code-splitting
 * this generates a separate chunk (about.[hash].js) for this route
 * which is lazy-loaded when the route is visited.
 */
const pages = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'home' */ '../views/Home.vue'),
    meta: { isHeaderFixed: true, isScrollSmooth: false },
    beforeEnter: (to, from, next) => {
      if (window.navigator.language.toLowerCase().endsWith('au')) {
        window.location.href = 'https://lollipopcamera.au/';
      }
      next();
    },
  },
  // Products
  {
    path: '/product/camera',
    name: 'Product Camera',
    component: () => import(/* webpackChunkName: 'camera' */ '../views/Camera.vue'),
    meta: { isHeaderFixed: false, isScrollSmooth: false },
  },
  {
    path: '/product/care',
    name: 'Product Care',
    component: () => import(/* webpackChunkName: 'care' */ '../views/Care.vue'),
    meta: { isHeaderFixed: true, isScrollSmooth: false },
  },
  {
    path: '/product/tableware',
    name: 'Product Tableware',
    component: () => import(/* webpackChunkName: 'tableware' */ '../views/Tableware.vue'),
    meta: { isHeaderFixed: false, isScrollSmooth: true },
  },
  /*
  {
    path: '/product/sillicone-feeding-bib',
    beforeEnter: () => {
      window.location.href = 'https://www.lollipop.camera/product/sillicone-feeding-bib/';
    },
  },
  */
  {
    path: '/camera',
    name: 'Camera',
    redirect: () => ({ path: '/product/camera' }),
  },
  {
    path: '/care',
    name: 'Care',
    redirect: () => ({ path: '/product/care' }),
  },
  {
    path: '/tableware',
    name: 'Tableware',
    redirect: () => ({ path: '/product/tableware' }),
  },
  // Others
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: 'about' */ '../views/About.vue'),
    meta: { isHeaderFixed: true, isScrollSmooth: false },
  },
  {
    path: '/sale-channel',
    name: 'SaleChannel',
    component: () => import(/* webpackChunkName: 'saleChannel' */ '../views/SaleChannel.vue'),
    meta: { isHeaderFixed: true, isScrollSmooth: false },
  },
  // 產品說明上的 iOS APP Store QRcode 導向連結
  {
    path: '/iosapp',
    beforeEnter: () => {
      window.location.href = 'https://itunes.apple.com/app/id998574475';
    },
  },
  // 產品說明上的 android Google Play QRCode 導向連結
  {
    path: '/androidapp',
    beforeEnter: () => {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.aoitek.lollipop';
    },
  },
  // Privacy, 目前已知 alexa 相關會需要使用到
  {
    path: '/privacy',
    beforeEnter: () => {
      window.location.href = 'https://support.lollipop.camera/hc/en-us/articles/900001317923-Privacy-Policy';
    },
  },
  // Terms, 目前已知 alexa 相關會需要使用到
  {
    path: '/terms',
    beforeEnter: () => {
      window.location.href = 'https://support.lollipop.camera/hc/en-us/articles/900001317943-Terms-and-Conditions';
    },
  },
];

// 路徑有包含多國語言，SEO 友好
const getLangPages = () => {
  const pageArray = [];

  if (process.env.NODE_ENV !== 'prerender') {
    // 一般狀況，導引多國語言頁面至正常格式的 url https:/xxx/?lang=xxx
    pages.forEach((page) => {
      if (Object.prototype.hasOwnProperty.call(page, 'meta')) {
        const aliasPage = page.path === '/' ? '/:lang/index.html' : `/:lang${page.path}/index.html`;
        const langPage = {
          path: `/:lang${page.path}`,
          name: `lang-${page.name}`,
          alias: [aliasPage],
          redirect: (to) => ({ path: page.path, query: { lang: to.params.lang } }),
        };
        pageArray.push(langPage);

        // const path = page.path !== '/' ? page.path : '';
        // const langPage = {
        //   path: `/:lang${path}`,
        //   name: `lang-${page.name}`,
        //   alias: [`/:lang${path}/index.html`],
        //   redirect: (to) => ({ path, query: { lang: to.params.lang } }),
        // };
        // pageArray.push(langPage);
      }
    });
    pageArray.push({
      // TODO: page not found
      path: '/:lang/*',
      name: 'lang-NotFoundPage',
      component: () => import(/* webpackChunkName: 'home' */ '../views/Home.vue'),
      meta: { isHeaderFixed: true, isScrollSmooth: false },
    });
  } else {
    // Prerender 產生真實頁面，如果用 redirect 則會被忽略
    pages.forEach((page) => {
      if (Object.prototype.hasOwnProperty.call(page, 'meta')) {
        const path = page.path !== '/' ? page.path : '';
        const langPage = {
          ...page,
          name: `lang-${page.name}`,
          path: `/:lang${path}`,
          alias: [`/:lang${path}/index.html`],
        };
        pageArray.push(langPage);
      }
    });
  }

  return pageArray;
};

const notFoundPage = {
  // TODO: page not found
  path: '*',
  name: 'NotFoundPage',
  component: () => import(/* webpackChunkName: 'home' */ '../views/Home.vue'),
  meta: { isHeaderFixed: true, isScrollSmooth: false },
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...pages, ...(getLangPages()), notFoundPage],
});

router.beforeEach((to, from, next) => {
  /** 判斷 header 是否要固定在上方 */
  if (to.meta.isHeaderFixed) {
    $store.commit('updateIsHeaderFixed', true);
  } else {
    $store.commit('updateIsHeaderFixed', false);
  }

  /** 判斷 css 是否要加上 scroll-smooth 的屬性  */
  const htmlElement = document.getElementsByTagName('html')[0];
  if (to.meta.isScrollSmooth) {
    htmlElement.classList.add('scroll-smooth');
  } else {
    htmlElement.classList.remove('scroll-smooth');
  }

  /** 設定語系 */
  // console.log(`lang params:${to.params.lang} query:${to.query.lang}`);
  let urlLanguageCode = to.params.lang === undefined ? to.query.lang : to.params.lang;

  // SEO for tw pages
  if (urlLanguageCode === 'tw') urlLanguageCode = 'zh-Hant';

  const defaultLanguageCode = languageUtil.codeTransfer(window.navigator.language);
  if (languageList.includes(urlLanguageCode)) {
    $store.commit('updateLanguageCode', urlLanguageCode);
  } else if (defaultLanguageCode && !$store.state.languageCode) {
    $store.commit('updateLanguageCode', defaultLanguageCode);
  }

  next();
});

export default router;
